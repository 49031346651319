import React, { useEffect, useState } from "react"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import { graphql, PageProps } from "gatsby"
import Layout from "../../components/Layouts/mainPage"
import { PageData } from "../../interfaces/pageProps"
import { Banner } from "../../components/Banners/PkwOzon"
import { Banner as RoundedDynamicBanner } from "../../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { CardWithMaxBenefits } from "../../components/CardWithMaxBenefits"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { Benefits } from "../../components/Benefits/BenefitsVideo"
import { OtherCards } from "../../components/OtherCards"
import { NewCalculator } from "../../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../../components/MorBenefitsRedisgn"
import { BottomCta, RefinSeoBgImg } from "../../components/BottomCta"
import { Partners } from "../../components/PartnersNew"
import { SwitcherTextBlock } from "../../components/SwitcherTextBlock"
import { NewHeader } from "../../components/Headers/NewHeader"
import { NewFooter } from "../../components/NewFooter"
import { getPageData } from "../../helpers/getPageData"

import { BannerText, getDynamicBannerText } from "../../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../../helpers/getDynamicBannerUrl"
import { FormPKW } from "../../components/FormPKW/FormPKW"
import { isKameleoonVariation } from "../../utils/kameleoonCheckVariation"
import { ITEMS_PROMOPAGES } from "../../components/CardWithMaxBenefits/helpers"

const bannerTitle = `Промокод 1000 ₽ <br /> на карту "Халва" <br /> для пользователей VK`

const bannerDescription = `— До 17% на остаток по карте<br /> — Кэшбэк до 10%<br /> — Обслуживание 0 ₽<br />`
const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const titleHow = (
  <>
    Как получить кэшбэк
    <br />
    500 рублей
  </>
)

const longForm = isKameleoonVariation("21632667_halva_form_long", 874539)

export default function VKoffer({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any | boolean>()

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (
        bannerImagesData &&
        bannerImagesData.dymanicBannerDesk !== null &&
        bannerImagesData.dymanicBannerMob !== null
      ) {
        setDynamicBannerImagesObj(bannerImagesData)
      } else {
        setDynamicBannerImagesObj(false)
      }
    }
  }, [bannerImageUrl, data.allDynamicBanner])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])
  const GIFT_TITLE = (
    <>
      Оформите Халву и получите
      <br />
      <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
    </>
  )
  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader />
      {dynamicBannerImagesObj ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <Banner orderNum="1" />
      )}
      <CardWithMaxBenefits
        headTitle="Преимущества с Халвой:"
        items={ITEMS_PROMOPAGES}
        orderNum="2"
      />
      <FormPKW
        dataLayerName={longForm ? "longPersonalForm" : "shortPersonalForm"}
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm={longForm}
        formBtnText="Оформить карту"
        orderNum="3"
        onlyPKWPage
        isGreenApprove
        hasBirth
        productName="КК Халва"
        emptyForm={false}
        thanksPage="/success-pkw/"
      />
      <HowInstallmentWorks headTitle={titleHow} variant="pkwOzon" orderNum="4" />
      <Benefits orderNum="5" />
      <OtherCards orderNum="6" />
      <NewCalculator orderNum="7" />
      <MoreBenefitsRedisign additionalEventInDataLayer={false} bottomBlock={false} orderNum="8" />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="9" />
      <Partners title="Магазины-партнеры" orderNum="10" />
      <SwitcherTextBlock mode="bezProcentov" orderNum="11" seoBlockText="" indent />
      <NewFooter ligal={ligal} orderNum="12" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/ozon/") {
        url
        notIndex
        ligal {
          text
        }
      }
    }
  }
`
