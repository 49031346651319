type KameleoonExperiment = {
  name: string
  associatedVariation: {
    id: number
    name: string
  }
}

export function isKameleoonVariation(experimentName: string, variationId: number): boolean {
  const exp: { experiment: KameleoonExperiment } = (
    globalThis as any
  )?.Kameleoon?.API?.CurrentVisit?.activatedExperiments
    ?.filter(
      (data: { experiment: KameleoonExperiment }) => data?.experiment?.name === experimentName
    )
    .at(0)

  return exp?.experiment?.associatedVariation?.id === variationId
}

export function isKameleoonActivatedExperiment(experimentName: string): boolean {
  return !!(globalThis as any)?.Kameleoon?.API?.CurrentVisit?.activatedExperiments?.filter(
    ({ experiment }: { experiment: KameleoonExperiment }) => experiment?.name === experimentName
  ).length
}
